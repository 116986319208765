
import { Component, Ref, Vue } from "vue-property-decorator";
import { bus } from "@/main";
import LayoutModal from "@/layouts/components/modal.vue";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
    },
})
export default class PreviewMediaModal extends Vue {
    show = false;

    loading = false;

    media: { mime_type: string; original_url: string } | null = null;

    @Ref() modal!: LayoutModal;

    mounted() {
        bus.$off("show-preview-media");
        bus.$on("show-preview-media", (payload: { mime_type: string; original_url: string } | string) => {
            let media = payload;

            if (typeof media === "string") {
                if (media.endsWith(".pdf")) {
                    media = {
                        mime_type: "application/pdf",
                        original_url: media,
                    };
                } else if (media.endsWith(".png") || media.endsWith(".jpg") || media.endsWith(".jpeg")) {
                    media = {
                        mime_type: media.endsWith(".png") ? "image/png" : "image/jpeg",
                        original_url: media,
                    };
                }
            }

            this.media = media as { mime_type: string; original_url: string };
            this.show = true;
            this.modal.focus();
        });
    }

    handleClose() {
        this.show = false;
    }

    get modalWidth() {
        return this.media && this.media.mime_type === "application/pdf" ? 680 : "auto";
    }

    get isIOS() {
        return !/Chrome/.test(navigator.userAgent) && /iPad|iPhone|iPod|Safari/.test(navigator.userAgent);
    }
}
